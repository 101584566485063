@import styles/sass/abstracts

.main
  width: 100%
  text-align: center
  margin-bottom: 30px

.title
  +font(48, 52, 700)
  +res-sm-max
    +font(28, 33, 700)

.quarter
  display: flex
  flex-wrap: wrap
  gap: $gap-sm-2
  padding-bottom: $gap-sm-2
  +res-sm-max
    gap: $gap-lg-1
    padding-bottom: $gap-lg-1
  > *
    flex: 1 0 49%
